var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        key: _vm.$options.name,
        ref: _vm.$options.name,
        staticClass: "InvoiceTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          customFilterDropdowns: _vm.filterDropdowns,
          customHeadingTranslations: { RemarkOwner: _vm.$t("colleague") },
          headerTitle: "invoices2468",
          tooltipMsg: "manualforinvoic",
          addNewRecordMsg: "",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "InvoiceDatetimeCreated", ascending: 0 },
          modelId: "InvoiceId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "filter__Remark",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center" },
                    [
                      _c(
                        "b-form-select",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.handleFilter()
                            },
                          },
                          model: {
                            value: _vm.filterModel.Remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterModel, "Remark", $$v)
                            },
                            expression: "filterModel.Remark",
                          },
                        },
                        _vm._l(_vm.RemarkItems, function (group) {
                          return _c(
                            "optgroup",
                            {
                              key: group.groupLabel,
                              attrs: { label: group.groupLabel },
                            },
                            _vm._l(group.groupOption, function (option) {
                              return _c(
                                "option",
                                {
                                  key: option.value,
                                  domProps: { value: option.value },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(option.text) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        }),
                        0
                      ),
                      _c("label", [_vm._v("  ")]),
                      _c("b-form-select", {
                        attrs: { options: _vm.filterDropdowns.RemarkOwner },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilter()
                          },
                        },
                        model: {
                          value: _vm.filterModel.RemarkOwner,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterModel, "RemarkOwner", $$v)
                          },
                          expression: "filterModel.RemarkOwner",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "filter__INumber",
              fn: function () {
                return [
                  _c("b-form-input", {
                    attrs: { list: "my-list-id" },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilter()
                      },
                    },
                    model: {
                      value: _vm.filterModel.INumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.filterModel, "INumber", $$v)
                      },
                      expression: "filterModel.INumber",
                    },
                  }),
                  _c(
                    "datalist",
                    { attrs: { id: "my-list-id" } },
                    _vm._l(_vm.invoiceTypeFilter, function (invType) {
                      return _c("option", { key: invType }, [
                        _vm._v(_vm._s(invType)),
                      ])
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "INumber",
              fn: function (list) {
                return [
                  _c("invoice-link", {
                    attrs: {
                      iNumber: list.row.INumber,
                      iPFNumber: list.row.IPFNumber,
                      iCode: list.row.ICode,
                      iPFCode: list.row.IPFCode,
                      iType: list.row.InvoiceType,
                      wrapperClass: "d-flex flex-column",
                    },
                  }),
                  list.row.InvoiceIsReinvoice
                    ? _c("label", [_vm._v("[ReFa]")])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "AcompanyId",
              fn: function (list) {
                return [_vm._v(_vm._s(list.row.InvoiceAccountingCompanyName))]
              },
            },
            {
              key: "UserId",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrlForEdit("contact", list.row.ContactId),
                        target: "_blank",
                      },
                    },
                    [_vm._v("P" + _vm._s(list.row.UserId))]
                  ),
                ]
              },
            },
            {
              key: "ObjectId",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrlForEdit("object", list.row.ObjectId),
                        target: "_blank",
                      },
                    },
                    [_vm._v("O" + _vm._s(list.row.ObjectId))]
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrlForDetail(
                          list.row.UrlDomain,
                          list.row.UrlDetail
                        ),
                        target: "_blank",
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "eye", cursor: "pointer", size: "lg" },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "InvoiceState",
              fn: function (list) {
                return [
                  _vm.isPaid(list.row.InvoiceState)
                    ? _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(list.row.InvoiceDatetimePayment)
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm.isNotPaid(list.row.InvoiceState)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "p-0 pr-1",
                              attrs: { variant: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmPayment(list.row.InvoiceId)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("addpayment")))]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "pointer",
                              attrs: { title: _vm.$t("cancelinvoice") },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "cancelinvoice-icon",
                                attrs: {
                                  tooltip: _vm.$t("cancelallfilter"),
                                  icon: ["fas", "times"],
                                  size: "lg",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelInvoice(list.row.InvoiceId)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "PriceTotal",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      _vm._f("currency")(
                        list.row.PriceTotal,
                        list.row.CurrencyCode
                      )
                    )
                  ),
                ]
              },
            },
            {
              key: "InvoiceDatetimeCreated",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm._f("dateTime")(list.row.InvoiceDatetimeCreated))
                  ),
                ]
              },
            },
            {
              key: "InvoiceDatetimeMaturity",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm._f("date")(list.row.InvoiceDatetimeMaturity))
                  ),
                ]
              },
            },
            {
              key: "InvoiceDatetimePayment",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm._f("date")(list.row.InvoiceDatetimePayment))
                  ),
                ]
              },
            },
            {
              key: "RecommendId",
              fn: function (list) {
                return [
                  list.row.IsRecommendAllowed
                    ? _c("recommend-cell", {
                        attrs: {
                          contactId: list.row.ContactId,
                          recommendId: list.row.RecommendId,
                          recommendValue: list.row.InvoiceRecommendValue,
                          recommendIdTexts: _vm.recommendIdTexts,
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "Remark",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { class: _vm.remarkWrapperClass(list.row) },
                    [
                      _c("remark-indicate", {
                        attrs: {
                          entityId: list.row.InvoiceId,
                          entityTypeId: _vm.remarkEntityType,
                          remarkTypeId: 1,
                          refreshMethod: _vm.tableGetData,
                          defaultRemark: list.row.Remark,
                          acountantRemark: list.row.RemarkAccountant,
                          administratorList: _vm.administratorList,
                        },
                      }),
                      _c("invoice-note", {
                        attrs: {
                          invoiceId: list.row.InvoiceId,
                          refreshMethod: _vm.tableGetData,
                          invoiceNote: list.row.InvoiceNote,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _c("email-send-dropdown", {
                    attrs: {
                      buttonText: _vm.$t("sendanoffer"),
                      pesLogTypeId: _vm.pesLogTypeId,
                      offersList: _vm.emailsList,
                      entityId: +list.row.InvoiceId,
                      withTable: false,
                    },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          3747264989
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }