// TODO chyba tu stlpec InvoiceDatetimePayment - je na to filter v starej tabulke

export const tableColumns = [
	{
		model: 'Remark',
		i18n: 'remark2317',
		sortable: true,
	},
	{
		model: 'UserId',
		i18n: 'pid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ObjectId',
		i18n: 'oid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'INumber',
		i18n: 'invoice2475',
		sortable: true,
	},
	{
		model: 'AcompanyId',
		i18n: 'supplier',
		sortable: true,
		hidden: true,
		filter: 'dropdown',
	},
	{
		model: 'InvoiceVariableSymbol',
		i18n: 'variablesymbol2479',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'InvoiceAddressEmail',
		i18n: 'e-mail',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CustommerName',
		i18n: 'buyer',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RecommendId',
		i18n: 'recommendationf2483',
		sortable: true,
		filter: 'dropdown',
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		hidden: true,
		sortable: true,
	},
	{
		model: 'InvoiceDatetimeCreated',
		i18n: 'created',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'InvoiceDatetimeMaturity',
		i18n: 'duedate',
		sortable: true,
		filter: 'dropdown',
	},
	{
		model: 'PriceTotal',
		i18n: 'totalprice2486',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'InvoiceState',
		i18n: 'paid2485',
		sortable: true,
		filter: 'dropdown',
	},
];
