<template>
	<div class="note-wrapper">
		<b-modal
			v-model="showNoteModal"
			:title="$t('remark2469')"
			size="lg"
			no-close-on-backdrop
			centered
		>
			<b-textarea ref="textArea" rows="4" v-model="invoiceNoteText"></b-textarea>

			<template v-slot:modal-footer>
				<megau-button
					icon="check"
					:title="$t('savenote')"
					@handleClick="handleUpdateInvoiceNote"
					classprop="btn btn-success"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDeleteInvoiceNote"
					:title="$t('deletenote')"
					classprop="btn btn-danger"
				></megau-button>
			</template>
		</b-modal>
		<div>
			<div
				v-show="showInvoiceNote"
				@click="showNoteModal = true"
				v-b-tooltip.hover
				:title="this.invoiceNote"
				class="comment-shape mt-1"
			>{{$t('remark2530')}}</div>
			<div v-b-tooltip.hover :title="$t('addanote')">
				<font-awesome-icon
					class="addremark-icon"
					v-show="showAddIcon"
					:icon="['fas', 'plus']"
					size="lg"
					cursor="pointer"
					@click="showNoteModal = true"
				></font-awesome-icon>
			</div>
		</div>
	</div>
</template>

<script>
import service from './invoice.service.js';
import http from '../../services/helpers/http';
import serviceCommon from '../../services/service/common.service';

export default {
	props: {
		invoiceNote: { type: String },
		invoiceId: { type: Number },
		refreshMethod: { type: Function },
	},
	data() {
		return {
			showNoteModal: false,
			invoiceNoteText: this.invoiceNote,
		};
	},

	computed: {
		showInvoiceNote() {
			return this.invoiceNote ? true : false;
		},
		showAddIcon() {
			return !this.invoiceNote ? true : false;
		},
	},
	methods: {
		async handleDeleteInvoiceNote() {
			if (this.invoiceNoteText) {
				service.handleDeleteWithModal(this.invoiceId, this.$modal, this.closeModal);
			}
		},
		async handleUpdateInvoiceNote() {
			if (this.invoiceNoteText) {
				await http.put('Invoice/PutInvoiceNote', { invoiceId: this.invoiceId, invoiceNote: this.invoiceNoteText });
				this.closeModal();
			}
		},
		closeModal() {
			this.showNoteModal = false;
			this.refreshMethod();
		},
	},
	watch: {
		invoiceNote() {
			this.invoiceNoteText = this.invoiceNote;
		},
	},
};
</script>

<style lang="scss">
.comment-shape {
	@include remarkBadge(115px, 20px, var(--mu-light-green));
}
.comment-shape-small {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--mu-light-green);
	color: white;
}
.addremark-icon {
	color: var(--mu-icongray);
}
</style>
