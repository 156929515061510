<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:key="$options.name"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:customFilterDropdowns="filterDropdowns"
		:customHeadingTranslations="{ RemarkOwner: $t('colleague') }"
		headerTitle="invoices2468"
		tooltipMsg="manualforinvoic"
		addNewRecordMsg
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'InvoiceDatetimeCreated', ascending: 0 }"
		modelId="InvoiceId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="InvoiceTable"
	>
		<template #filter__Remark>
			<div class="d-flex flex-row align-items-center">
				<b-form-select @change="handleFilter()" v-model="filterModel.Remark">
					<optgroup v-for="group in RemarkItems" :label="group.groupLabel" :key="group.groupLabel">
						<option v-for="option in group.groupOption" :value="option.value" :key="option.value">
							{{ option.text }}
						</option>
					</optgroup>
				</b-form-select>
				<label>&nbsp;&nbsp;</label>
				<b-form-select
					@change="handleFilter()"
					v-model="filterModel.RemarkOwner"
					:options="filterDropdowns.RemarkOwner"
				></b-form-select>
			</div>
		</template>

		<template #filter__INumber>
			<b-form-input v-on:keydown.enter="handleFilter()" v-model="filterModel.INumber" list="my-list-id"></b-form-input>
			<datalist id="my-list-id">
				<option :key="invType" v-for="invType in invoiceTypeFilter">{{ invType }}</option>
			</datalist>
		</template>

		<template #INumber="list">
			<invoice-link
				:iNumber="list.row.INumber"
				:iPFNumber="list.row.IPFNumber"
				:iCode="list.row.ICode"
				:iPFCode="list.row.IPFCode"
				:iType="list.row.InvoiceType"
				wrapperClass="d-flex flex-column"
			></invoice-link>
			<label v-if="list.row.InvoiceIsReinvoice">[ReFa]</label>
		</template>

		<template #AcompanyId="list">{{ list.row.InvoiceAccountingCompanyName }}</template>

		<template #UserId="list">
			<a :href="getUrlForEdit('contact', list.row.ContactId)" target="_blank">P{{ list.row.UserId }}</a>
		</template>

		<template #ObjectId="list">
			<a :href="getUrlForEdit('object', list.row.ObjectId)" target="_blank">O{{ list.row.ObjectId }}</a>

			<a :href="getUrlForDetail(list.row.UrlDomain, list.row.UrlDetail)" target="_blank">
				<font-awesome-icon icon="eye" cursor="pointer" size="lg"></font-awesome-icon>
			</a>
		</template>

		<template #InvoiceState="list">
			<div v-if="isPaid(list.row.InvoiceState)">{{ list.row.InvoiceDatetimePayment | date }}</div>
			<div v-if="isNotPaid(list.row.InvoiceState)" class="d-flex justify-content-between align-items-center">
				<b-button @click="confirmPayment(list.row.InvoiceId)" variant="link" class="p-0 pr-1">{{ $t('addpayment') }}</b-button>
				<span class="pointer" v-b-tooltip.hover :title="$t('cancelinvoice')">
					<font-awesome-icon
						class="cancelinvoice-icon"
						:tooltip="$t('cancelallfilter')"
						@click="cancelInvoice(list.row.InvoiceId)"
						:icon="['fas', 'times']"
						size="lg"
					></font-awesome-icon>
				</span>
			</div>
		</template>

		<template #PriceTotal="list">{{ list.row.PriceTotal | currency(list.row.CurrencyCode) }}</template>

		<template #InvoiceDatetimeCreated="list">{{ list.row.InvoiceDatetimeCreated | dateTime }}</template>

		<template #InvoiceDatetimeMaturity="list">{{ list.row.InvoiceDatetimeMaturity | date }}</template>

		<template #InvoiceDatetimePayment="list">{{ list.row.InvoiceDatetimePayment | date }}</template>

		<template #RecommendId="list">
			<recommend-cell
				v-if="list.row.IsRecommendAllowed"
				:contactId="list.row.ContactId"
				:recommendId="list.row.RecommendId"
				:recommendValue="list.row.InvoiceRecommendValue"
				:recommendIdTexts="recommendIdTexts"
			></recommend-cell>
		</template>

		<template #Remark="list">
			<div :class="remarkWrapperClass(list.row)">
				<remark-indicate
					:entityId="list.row.InvoiceId"
					:entityTypeId="remarkEntityType"
					:remarkTypeId="1"
					:refreshMethod="tableGetData"
					:defaultRemark="list.row.Remark"
					:acountantRemark="list.row.RemarkAccountant"
					:administratorList="administratorList"
				></remark-indicate>
				<invoice-note
					:invoiceId="list.row.InvoiceId"
					:refreshMethod="tableGetData"
					:invoiceNote="list.row.InvoiceNote"
				></invoice-note>
			</div>
		</template>

		<template #customActions="list">
			<email-send-dropdown
				:buttonText="$t('sendanoffer')"
				:pesLogTypeId="pesLogTypeId"
				:offersList="emailsList"
				:entityId="+list.row.InvoiceId"
				:withTable="false"
			></email-send-dropdown>
		</template>
	</table-page>
</template>

<script>
import { cloneDeep } from 'lodash';
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions, getColumnDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import service from './invoice.service';
import serviceDictionary from '@/services/service/dictionary.service';
import serviceEnums from '@/services/service/enums.service';
import apiUser from '@/services/api/user.api';
import utils from '@/services/helpers/utils';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import servicePortalEmail from '@/modules/portal-emails/portal-email.service';
import RemarkIndicate from '@/components/table/remark-indicate';
import InvoiceNote from './invoice-note.vue';
import RecommendCell from '@/components/table/recommend-cell';
import InvoiceLink from '@/components/common/invoice-link';

import { tableColumns } from './invoice.table-data';

export default {
	name: 'InvoiceTable',

	components: {
		TablePage,
		RemarkIndicate,
		InvoiceNote,
		RecommendCell,
		InvoiceLink,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'InvoiceListItem',
			administratorList: [],
			invoiceTypeFilter: ['PF', 'OF'],

			user: auth.GetUser(),
			filterDropdowns: {
				Remark: [],
				RemarkOwner: [],
			},
			remarkEntityType: enums.remarkEntityType.Invoice,
			pesLogTypeId: enums.sendLogsTypeEnum.Invoice,
			useQuickFilter: true,
		};
	},

	computed: {
		recommendIdTexts() {
			return getColumnDropdownOptions(this.columns, 'RecommendId');
		},
	},

	async created() {
		const [cols] = await Promise.all([this.prepareColumns(tableColumns), this.getAdminAndRemarkList()]);
		this.columns = cols;
	},

	methods: {
		redirectToDetail(InvoiceId = 0) {
			this.$router.push({
				name: links.invoiceDetailName,
				params: { InvoiceId },
			});
		},

		async prepareColumns(columns) {
			const emailIds = '58,5,68,69,3,70,71,72,22';

			const [accountingCompanies, registrationRecommends, emailsList] = await Promise.all([
				serviceDictionary.getAccountingCompanies(),
				serviceDictionary.getRegistrationRecommends(),
				servicePortalEmail.getEmailSubjectsByIds(emailIds).then((res) => res.data),
			]);

			this.emailsList = emailsList;

			let cols = prepareAcompanyId(columns);
			cols = prepareRecommendId(columns);
			cols = prepareInvoiceDatetimeMaturity(columns);
			cols = prepareInvoiceState(columns);

			return cols;

			function prepareAcompanyId(columns) {
				const values = accountingCompanies.accountingCompanySelectorItems;

				return setDropdownOptions(columns, 'AcompanyId', values);
			}

			function prepareRecommendId(columns) {
				const values = registrationRecommends.registrationRecommendSelectorItems;

				return setDropdownOptions(columns, 'RecommendId', values);
			}

			function prepareInvoiceDatetimeMaturity(columns) {
				const values = serviceEnums.getEnumForDropdown('invoiceMaturityFilter', true);

				return setDropdownOptions(columns, 'InvoiceDatetimeMaturity', values);
			}

			function prepareInvoiceState(columns) {
				const values = serviceEnums.getEnumForDropdown('invoiceState', true);

				return setDropdownOptions(columns, 'InvoiceState', values);
			}
		},

		async getAdminAndRemarkList() {
			let admins = await apiUser.getAdministratorList();
			this.administratorList = cloneDeep(admins.data);
			this.administratorList.unshift({ id: null, name: this.$i18n.t('unassigned') });
			admins.data.unshift({ id: -1, name: this.$i18n.t('unassigned') });
			const RemarkOwner = utils.convertItems(
				admins.data,
				function (item) {
					return { value: item.id, text: item.name };
				},
				{ value: null, text: '' }
			);

			const Remark = serviceEnums.getEnumForDropdown('remarkAccountant');

			this.RemarkItems = {
				groupOne: {
					groupLabel: this.$i18n.t('remarks'),
					groupOption: Remark.filter((item) => item.value < 9),
				},
				groupTwo: {
					groupLabel: this.$i18n.t('remarksforaccou'),
					groupOption: Remark.filter((item) => item.value >= 9),
				},
			};

			this.filterDropdowns = {
				Remark,
				RemarkOwner,
			};
		},

		getUrlForEdit(url, entityId) {
			return `${url}/${entityId}`;
		},

		getUrlForDetail(urlDomain, urlDetail) {
			return `${urlDomain}/${urlDetail}?tkn=${this.user.AutologCode}`;
		},

		remarkWrapperClass(row) {
			if ((row.Remark != null || row.RemarkAccountant != null) && row.InvoiceNote != '')
				return 'd-flex flex-column full-width-wrapper';
			if (row.Remark == null && row.RemarkAccountant == null && row.InvoiceNote == '') return 'd-flex justify-content-between';
			if (row.Remark == null && row.RemarkAccountant == null && row.InvoiceNote != '')
				return 'd-flex ordered-remark justify-content-between';
			if ((row.Remark != null || row.RemarkAccountant != null) && row.InvoiceNote == '')
				return 'd-flex ordered-note justify-content-between';
		},

		isPaid(invoiceState) {
			return invoiceState === enums.invoiceState.Paid || invoiceState === enums.invoiceState.Canceled;
		},

		isNotPaid(invoiceState) {
			return invoiceState === enums.invoiceState.Unpaid;
		},

		confirmPayment(invoiceId) {
			service.confirmInvoicePayment(invoiceId, this.$modal, this.stopLoadingWithRefresh, this.startLoading);
		},

		stopLoadingWithRefresh() {
			this.stopLoading();
			this.tableGetData();
		},

		cancelInvoice(invoiceId) {
			service.cancelInvoice(invoiceId, this.$modal, this.tableGetData);
		},
	},
};
</script>
<style lang="scss">
.InvoiceTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 100px;
		width: 100px;
	}
	.VueTables__filters-row > th:nth-child(3) {
		min-width: 50px;
		width: 50px;
	}
	.VueTables__filters-row > th:nth-child(4) {
		min-width: 50px;
		width: 50px;
	}
	.VueTables__filters-row > th:nth-child(5) {
		min-width: 105px;
		width: 105px;
	}
	.VueTables__filters-row > th:nth-child(6) {
		min-width: 75px;
		width: 75px;
	}
	.VueTables__filters-row > th:nth-child(7) {
		min-width: 110px;
		width: 110px;
	}
	.VueTables__filters-row > th:nth-child(9) {
		min-width: 50px;
		width: 50px;
	}
	.VueTables__filters-row > th:nth-child(8) {
		max-width: 100px;
	}
	.VueTables__filters-row > th:nth-child(11) {
		min-width: 80px;
		width: 80px;
	}
	.VueTables__filters-row > th:nth-child(12) {
		min-width: 70px;
		width: 70px;
	}
	.VueTables__filters-row > th:nth-child(13) {
		min-width: 80px;
		width: 80px;
	}
}

.ordered-remark {
	.comment-shape {
		margin-top: 0 !important;
	}
	.remark-wrapper {
		order: 2;
		margin-left: 5px;
	}
}

.ordered-note {
	.icons-container {
		> :nth-child(1) {
			margin-top: 0 !important;
		}
	}
	.note-wrapper {
		order: 2;
		margin-left: 5px;
	}
}

.cancelinvoice-icon {
	color: var(--primary);
	&:hover {
		color: var(--danger);
	}
}
</style>
